import * as React from 'react'
import Layout from '../components/layout'

const AboutMe = () => {
  return (
    <Layout>
      <p>About ME HERE!!! :)</p>
    </Layout>
  )
}

export default AboutMe
